import React from 'react';
import Layout from '../components/layout/Layout';
import { ContactForm } from '../components/ContactForm';
import { Map } from '../components/Map';
import { HeroHeader } from '../components/HeroHeader';
import { FeatureContact } from '../components/FeatureContact';
import { ContactUs } from '../components/ContactUs';
import { contacts } from '../data/contacts/contacts';
import Seo from '../components/layout/SEO';


const Contacts = () => {
  return (
    <Layout>
      <Seo
        title={"Contatti, Telefono e Indirizzo | Autoscuola Faro, Pisa"}
        description={"Chiamaci, scrivici o vieni a trovarci in sede. Autoscuola Faro è in centro a Pisa, in via Emilia numero 2."}
        keywords={[
          "Telefono autoscuola Faro Pisa",
          "Indirizzo autoscuola Faro Pisa",
          "Contatti autoscuola Faro Pisa"
        ]}
      />

      <ContactForm />
      <ContactUs contacts={contacts} />

      <Map />
    </Layout>
  )
};

export default Contacts;
